<template>
    <nav class = "navbar has-shadow" role="navigation">


    <div class="navbar-brand">
      <router-link class="navbar-item" to="/">
        <img :src="require('../../assets/logo-club.png')" alt="logo clubelek" height="64">
      </router-link>
      <a role="button" @click="showNav = !showNav" :class="{ 'is-active': showNav }" class="navbar-burger" data-target="navMenu" aria-label="menu" aria-expanded="false">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
      
    </div>

    <div id="navMenu" class="navbar-menu" :class="{ 'is-active': showNav }">
      <div class="navbar-start">


        <!--router-link class="navbar-item" to = "/transports">
          <span class="icon">
            <i class="fas fa-bicycle"></i>
          </span>
          <span class="icon">
            <i class="fas fa-subway"></i>
          </span>
          <span>Transports</span> 
        </router-link -->

        

        

        <div class="navbar-item has-dropdown ">
          <div class="navbar-link">
            <span class="icon">
              <i class="fas fa-calendar-alt"></i>
            </span>
            <span>Calendrier</span>  
          </div>
      
          <div class="navbar-dropdown ">
            <router-link class="navbar-item" @click="closeNav" to="/calendrier-club">
              <span class="icon">
                <i class="fas fa-calendar-alt"></i>
              </span>
              <span>Calendrier du club</span>  
            </router-link>
      
            <router-link class="navbar-item" @click="closeNav" to="/liste-cr" v-if="$keycloak.authenticated">
              <span class="icon">
                <i class="fas fa-file-alt"></i>
              </span>
              <span>Liste des CR</span>  
            </router-link>  
            <a v-else class="navbar-item is-italic" title="Connexion requise">
              <span class="icon">
                <i class="fas fa-lock"> </i>
              </span>
              <span>Liste des CR</span>  
            </a>
            <router-link class="navbar-item" @click="closeNav" to="/event-templates" v-if="userHasCred('calendar-editor')">
              <span class="icon">
                <i class="fas fa-calendar-plus"></i>
              </span>
              <span>Templates d'évènements</span>  
            </router-link>   
          </div>
        </div>

        <div v-if="$keycloak.authenticated" class="navbar-item has-dropdown ">
          <div class="navbar-link">
              <span class="icon">
                <i class="fas fa-cash-register"> </i>
              </span>
              <span>Trésorerie/Achats</span>  
          </div>
            <div class="navbar-dropdown ">
              <!--router-link class="navbar-item" @click="closeNav" to="/achats">
                <span class="icon">
                  <i class="fas fa-shopping-basket"> </i>
                </span>
                <span>Boutique</span>  
              </router-link-->
              <router-link class="navbar-item" @click="closeNav" to="/suggestions-achats">
                <span class="icon">
                  <i class="fas fa-gifts"> </i>
                </span>
                <span>Demandes d'achats</span>  
              </router-link>
              
              <router-link class="navbar-item" @click="closeNav" to="/notes-de-frais">
                <span class="icon">
                  <i class="fas fa-receipt"> </i>
                </span>
                <span>Notes de frais et factures</span>  
              </router-link>

              <hr class="navbar-divider"/>
              <router-link class="navbar-item" @click="closeNav" to="/suivi-budgets">
                <span class="icon">
                  <i class="fas fa-chart-pie"></i>
                </span>
                <span>Suivi des budgets</span>  
              </router-link>
              <hr class="navbar-divider"/>
              <router-link v-if="userHasCred('bureau')" @click="closeNav" class="navbar-item" to="/treso">
                <span class="icon">
                  <i class="fas fa-balance-scale"></i>
                </span>
                <span>Gestion de la trésorerie</span>  
              </router-link>
            </div>
          
        </div>
        <div v-else class="navbar-item is-italic" title="Connexion requise">
            <span class="icon">
              <i class="fas fa-lock"> </i>
            </span>
            <span>Trésorerie/Achats</span>  
        </div>

        

        <div class="navbar-item has-dropdown ">
          <div class="navbar-link">
            <span class="icon">
              <i class="fas fa-tools"> </i>
            </span>
            <span>Emprunts</span>  
          </div>
          <div class="navbar-dropdown ">
            <router-link class="navbar-item" @click="closeNav" to="/emprunts">
              <span class="icon">
                <i class="fas fa-tools"> </i>
              </span>
              <span>Emprunter</span>  
            </router-link>

            <router-link class="navbar-item" @click="closeNav" to="/emprunts/retours">
              <span class="icon">
                <i class="fas fa-undo"> </i>
              </span>
              <span>Rendre</span>  
            </router-link>

            <router-link class="navbar-item" @click="closeNav" to="/emprunts/visualiser">
              <span class="icon">
                <i class="fas fa-eye"> </i>
              </span>
              <span>Visualiser les emprunts</span>  
            </router-link>
          </div>
        </div>

        

        <router-link class="navbar-item" @click="closeNav" to="/adhesion-club">
          <span class="icon">
            <i class="fas fa-user-plus"></i>
          </span>
          <span>Adhérer au club</span>  
        </router-link>
        

        <div class="navbar-item has-dropdown " v-if="userHasCred('bureau') || userHasCred('access-address-book')">
          <a class="navbar-link">
            <span class="icon ">
              <i class="fas fa-toolbox"></i>
            </span>
            <span>Gestion de l'asso</span>
          </a>
      
          <div class="navbar-dropdown ">
            <router-link class="navbar-item" @click="closeNav" to="/admin/address-book">
              <span class="icon">
                <i class="fas fa-address-book"></i>
              </span>
              <span>Carnet d'adresses</span>
            </router-link>
            <div v-if="userHasCred('bureau')">
              <hr class="navbar-divider"/>
              <router-link class="navbar-item" @click="closeNav" to="/admin/adhesion-club">
                <span class="icon">
                  <i class="fas fa-user-check"></i>
                </span>
                <span>Suivi des inscriptions au club</span>
              </router-link>
              <router-link class="navbar-item" @click="closeNav" to="/admin/groups-editor">
                <span class="icon">
                  <i class="fas fa-users-cog"></i>
                </span>
                <span>Gestion des groupes utilisateurs</span>
              </router-link>
              <router-link class="navbar-item" @click="closeNav" to="/admin/doorbell">
                <span class="icon">
                  <i class="fas fa-concierge-bell"></i>
                </span>
                <span>Accès à la sonnette</span>
              </router-link>
              <hr class="navbar-divider"/>
              <router-link class="navbar-item" @click="closeNav" to="/admin/skills">
                <span class="icon">
                  <i class="fas fa-graduation-cap"></i>
                </span>
                <span>Gestion des compétences</span>
              </router-link>
              <hr class="navbar-divider"/>
              <!--router-link class="navbar-item" @click="closeNav" to="/admin/mail-templates-editor">
                <span class="icon">
                  <i class="fas fa-envelope-open-text"></i>
                </span>
                <span>[SOON] Editeur de templates mails</span>
              </router-link-->
              <router-link class="navbar-item" @click="closeNav" to="/admin/archive">
                <span class="icon">
                  <i class="fas fa-archive"></i>
                </span>
                <span>Archiver l'intranet</span>
              </router-link>
            </div>
            
          </div>
          
        </div>

      </div>

      <div class="navbar-end">
        <div class="navbar-item has-dropdown " v-if="$keycloak.authenticated">
          <a class="navbar-item">
            <div class="button navbar-link is-light is-inverted is-outlined">
              <span>
                <i class="fas fa-user"></i>
              <span class="pl-3">{{$keycloak.userName}}</span>
              </span>
            </div>
          </a>
          <div class="navbar-dropdown">
            <router-link class="navbar-item" @click="closeNav" to="/me/skills">
              <span class="icon">
                <i class="fas fa-user-graduate"></i>
              </span>
              <span>Compétences</span>
            </router-link>
            <hr class="navbar-divider"/>
            <router-link class="navbar-item" @click="closeNav" to="/me">
              <span class="icon">
                <i class="fas fa-user-cog"></i>
              </span>
              <span>Mon compte</span>
            </router-link>
            <hr class="navbar-divider"/>
            <a class="navbar-item has-text-danger"  @click="$keycloak.logoutFn()">
              <span class="icon is-small">
                <i class="fas fa-sign-out-alt"></i>
              </span>
              <span><span class="is-size-6">Se déconnecter<br></span> 
              </span>
            </a>
          </div>
        </div>
        
        <div class="navbar-item" v-else>
          <div class="buttons">
            <a class="navbar-item button is-light is-inverted is-outlined" @click="$keycloak.login({redirectUri: getLoginRedirectUri()})">
              <span class="icon is-small">
                <i class="fas fa-user-lock"></i>
              </span>
              <span>Se connecter</span>
            </a>
          </div>
        </div>
      </div>
      <div class="navbar-item">
        <div class="buttons has-addons">
          <a class="button is-info is-outlined" href="https://www.clubelek.fr/" target="_blank">
          <span class="icon is-small">
            <i class="fas fa-globe"></i>
          </span>
            <strong>Site du club</strong>
          </a>
          <a class="button is-link is-outlined" href="https://3615.clubelek.fr/" target="_blank">
            <span class="icon is-small">
              <i class="fas fa-toolbox"></i>
            </span>
            <strong>3615 Clubelek</strong>
          </a>      
        </div>
      </div>
    </div>



  </nav>
</template>

<script>

export default {
  name: 'MainNavbar',
  data: function() {
    return {
      showNav: false,
    }
  },
  methods: {
    userHasCred(cred) {
      return this.$keycloak.hasResourceRole?this.$keycloak.hasResourceRole(cred):false
    },
    getLoginRedirectUri() {
      return window.location.protocol + "//" + window.location.host + '/#' + this.$route.path
    },
    closeNav() {
      setTimeout(() => {
        const menuDropdowns = Array.prototype.slice.call(this.$el.querySelectorAll('.has-dropdown'), 0);
        menuDropdowns.forEach( el => {
            el.classList.remove('is-active')
        });  
        this.showNav = false;
      }, 150)
    }
  },

  mounted: function() {
    const menuDropdowns = Array.prototype.slice.call(this.$el.querySelectorAll('.has-dropdown'), 0);
    menuDropdowns.forEach( el => {
      el.addEventListener('mouseover', () => {
        el.classList.add('is-active');
      });
      el.addEventListener('mouseout', () => {
        el.classList.remove('is-active');
      });
    });
  }

}
</script>

