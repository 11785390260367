import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueKeycloak from '@dsb-norge/vue-keycloak-js'

require('./particles.min.js');


require('@/assets/main.scss');
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";





axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

const app = createApp(App);
app.use(store)
app.use(VueAxios, axios)
app.use(VueKeycloak, {
    init: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
    },
    config : {
        url: process.env.VUE_APP_KEYCLOAK_URL, realm: process.env.VUE_APP_KEYCLOAK_REALM, clientId: process.env.VUE_APP_KEYCLOAK_CLIENTID
    },
    logout: {
        redirectUri: process.env.VUE_APP_LOGOUT_URL
    },
    onReady: (keycloak) => {
        app.use(router(keycloak))
        app.mount('#app');
    }
})



 