<template>
    <div class="footer mt-5">
        <div class="content has-text-centered">
            <p>
            Made with 💚 by Antoine Rochebois. CC-BY-CA-NC Clubelek 2022.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainFooter'
}
</script>