<template>

<div>
  <div id="particles-js"></div>
  <img v-if="showCake" class="spinningCake" :src="require('./assets/cake.png')">
  <MainNavbar/>
  <router-view v-slot="{ Component }">
    <transition name="fade">
      <component :is="Component" />
    </transition>
  </router-view>
  <MainFooter/>
  
</div>
</template>

<script>
import MainNavbar from './views/widgets/main-navbar.widget.vue';
import MainFooter from './views/widgets/main-footer.widget.vue';



export default {
  components: { MainNavbar, MainFooter, },
  data () {
    return {  
      showCake: false,
    }
  },
  created: function() {
    this.axios.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${this.$keycloak.token}`
      return config
    }, error => {
      return Promise.reject(error)
    })
    window.particlesJS.load('particles-js', '/particles.json', function() {
      console.log('callback - particles.js config loaded');
    });
    
  },

  mounted: async function() {
    setTimeout(async () => {
    if (this.$keycloak.authenticated) {
      const userAdhesions = (await this.axios.get('/api/adhesion/me'))?.data;
      this.$store.commit('setUserLastAdhesion', userAdhesions[userAdhesions.length - 1]);
      const birthdateString = this.$store.state.userLastAdhesion?.formData?.birthdate;
      if (!birthdateString) return;
      //JJ/MM/AAAA to date
      const birthdate = new Date(birthdateString.split("/").reverse().join("-"));
      const isTodayBirthday = birthdate.getDate() == new Date().getDate() && birthdate.getMonth() == new Date().getMonth();
  
      if (isTodayBirthday) {
        //on first click, play song
        window.document.addEventListener('click', () => {
          this.showCake = true;
          const audio = new Audio('/songs/anniv.mp3');
          audio.loop = true;
          audio.play()
        }, {once: true}
        );
      }
    }
    }, 1000);
  },
}
</script>

<style>
#particles-js {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-size: cover;
  background-position: 50% 50%;
  z-index: -1;
}

.spinningCake {
  /* in overlay in the middle of the screen with a z-index of 1000 and a spinning animation */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index:1000;
  animation: spin 2s linear infinite;
  animation-direction: alternate;
}

@keyframes spin {
  from {
      transform:translate(-50%, -50%) rotate(0deg) scale(2) skew(30deg, 20deg);
      filter: hue-rotate(0deg);
  }
  to {
      transform:translate(-50%, -50%) rotate(360deg) scale(1);
      filter: hue-rotate(360deg);
  }
}
</style>
